import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Form } from 'antd';
import { useNavigate } from 'react-router-dom';

import styles from './MainPage.module.scss';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import FormInput from '../../../components/FormItems/FormInput';
import { RolesInterceptor } from '../../../components/Interceptors/RolesInterceptor';
import { SUBMIT_METHOD_ADD, ROLES } from '../../../types/constants';

interface PageProps {
  titlePage: string;
  submitMethod: string;
}

export const MainPage: React.FC<PageProps> = ({ titlePage, submitMethod }) => {
  const { request } = useHttp();
  const [editor, setEditor] = useState(false);
  const [id, setId] = useState(0);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getData = useCallback(async () => {
    try {
      const fetchPage = await request({
        url: `${ApiUrl.PAGE}/url/main`,
        method: 'get',
      });
      setId(fetchPage.data?.id);

      form.setFieldsValue({
        title: fetchPage.data?.title,
        url: fetchPage.data?.url,
        metaTitle: fetchPage.data?.metaTitle,
        metaDescription: fetchPage.data?.metaDescription,
        description: fetchPage.data?.description,
        keywords: fetchPage.data?.keywords,
        content: fetchPage.data?.content,
        status: fetchPage.data?.status.toString(),
      });
    } catch (e) {}
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, [getData]);

  const onFinish = async (e: any) => {
    try {
      setLoading(true);
      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? { url: ApiUrl.PAGE, method: 'post', body: e }
          : { url: `${ApiUrl.PAGE}/${id}`, method: 'put', body: e };

      const response = await request(requestObject);
      setLoading(false);
      switch (response.config.method) {
        case 'post':
          navigate('/content/pages/list');
          return NotificationAlert('success', 'Страница добавлена');
        case 'put':
          return NotificationAlert('success', 'Страница обновлена');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response && Array.isArray(err.response.data)) {
        console.log('onFinish mainPage catch e: ', e);
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        console.log('onFinish mainPage catch e: ', e);
        return NotificationAlert('error', err.response.data?.message || err.message || 'Не удалось');
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <RolesInterceptor roles={[ROLES.content, ROLES.seo]}>
          <FormInput
            name={'title'}
            label={'Заголовок'}
            placeholder={'Заголовок'}
            textArea={true}
            labelCol={{ span: 24 }}
          />
          <Card
            style={{ marginTop: 16, textAlign: 'left' }}
            type="inner"
            title="Данные для поисковой оптимизации"
          >
            <FormInput
              name={'metaTitle'}
              label={'metaTitle'}
              placeholder={'Заголовок meta'}
              textArea={true}
              labelCol={{ span: 24 }}
            />
            <FormInput
              name={'keywords'}
              label={'metaKeywords'}
              placeholder={'Ключевые слова'}
              textArea={true}
              labelCol={{ span: 24 }}
            />
            <FormInput
              name={'metaDescription'}
              label={'metaDescription'}
              placeholder={'Описание'}
              textArea={true}
              labelCol={{ span: 24 }}
            />
          </Card>
        </RolesInterceptor>
        <Button
          id="editor"
          type="primary"
          onClick={() => setEditor(!editor)}
          style={{ marginBottom: 16, backgroundColor: 'black' }}
        ></Button>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
