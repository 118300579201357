import React, { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Form } from 'antd';

import styles from './GeoRegion.module.scss';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { statusGeoRegionOptions } from '../../../helpers/geoRegionHelper';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import { useTrimField } from '../../../hooks/useTrimField';
import { SUBMIT_METHOD_ADD } from '../../../types/constants';

interface GeoRegionProps {
  titlePage: string;
  submitMethod: string;
}

export const GeoRegion: React.FC<GeoRegionProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { id } = useParams();
  const { request } = useHttp();

  const navigate = useNavigate();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchGeoRegion = await request({
          url: `${ApiUrl.GEO_REGION}/${id}`,
          method: 'get',
        });

        form.setFieldsValue({
          name: fetchGeoRegion.data?.name,
          status: fetchGeoRegion.data?.status.toString(),
        });
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    if (!id) {
      form.setFieldsValue({
        status: '0',
      });
    }
  }, []);

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? { url: ApiUrl.GEO_REGION, method: 'post', body: e }
          : {
              url: `${ApiUrl.GEO_REGION}/${id}`,
              method: 'put',
              body: e,
            };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/content/geo/geo-region/list');
          return NotificationAlert('success', 'Регион добавлен');
        case 'put':
          return NotificationAlert('success', 'Регион обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response && Array.isArray(err.response.data)) {
        console.log('onFinish geoRegion catch e: ', e);
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        console.log('onFinish geoRegion catch e: ', e);
        return NotificationAlert('error', err.response.data?.message || err.message || 'Не удалось');
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form form={form} onFinish={onFinish} labelCol={{ span: 6 }}>
        <FormInput
          name={'name'}
          label={'Название региона'}
          placeholder={'Название региона'}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormSelect
          name={'status'}
          label={'Статус'}
          placeholder={'Выберите статус'}
          required={true}
          options={statusGeoRegionOptions}
          labelCol={{ span: 24 }}
        />
        <Form.Item>
          <Button style={{ marginTop: 16 }} type="primary" htmlType="submit">
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить регион' : 'Обновить регион'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
