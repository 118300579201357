export interface ICategory {
  id: number;
  name: string | null;
  parentId: string | null;
  description: string | null;
  shortDescription: string | null;
  slug: string | null;
  metaTitle: string | null;
  metaDescription: string | null;
  metaKeywords: string | null;
  metaCanonical: string | null;
  status: number;
  title: string | null;
  image: string[] | null;
  imageAlt: string | null;
  imageTitle: string | null;
  sort: number;
}

export interface CategoryState {
  categories: ICategory[];
  categoriesList: ICategory[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalCategories: number;
}

export enum CategoryActionTypes {
  FETCH_CATEGORY = 'FETCH_CATEGORY',
  FETCH_CATEGORIES = 'FETCH_CATEGORIES',
  FETCH_CATEGORIES_ERROR = 'FETCH_CATEGORIES_ERROR',
  SET_CATEGORY_PAGE = 'SET_CATEGORY_PAGE',
  SET_TOTAL_CATEGORY_PAGE = 'SET_TOTAL_CATEGORY_PAGE',
  FETCH_CATEGORIES_SELECT = 'FETCH_CATEGORIES_SELECT',
}

interface FetchCategoryAction {
  type: CategoryActionTypes.FETCH_CATEGORY;
}

interface FetchCategoriesAction {
  type: CategoryActionTypes.FETCH_CATEGORIES;
  payload: ICategory[];
}

interface FetchCategoriesErrorAction {
  type: CategoryActionTypes.FETCH_CATEGORIES_ERROR;
  payload: string;
}

interface SetCategoryPageAction {
  type: CategoryActionTypes.SET_CATEGORY_PAGE;
  payload: number;
}

interface SetTotalCategoryPageAction {
  type: CategoryActionTypes.SET_TOTAL_CATEGORY_PAGE;
  payload: number;
}

interface FetchCategoriesSelectAction {
  type: CategoryActionTypes.FETCH_CATEGORIES_SELECT;
  payload: ICategory[];
}

export type CategoryAction =
  | FetchCategoryAction
  | FetchCategoriesAction
  | FetchCategoriesErrorAction
  | SetCategoryPageAction
  | SetTotalCategoryPageAction
  | FetchCategoriesSelectAction;
