import React, { useEffect } from 'react';
import FormInput from '../FormItems/FormInput';

interface InputProps {
  value: string;
  form: any;
  name: number;
  type: string;
  fieldName?: string;
  label?: string;
  labelCol?: any;
  disabled?: boolean;
}

export const InputCustom: React.FC<InputProps> = ({
  value,
  name,
  form,
  type,
  label,
  fieldName = 'attributeTypeProductInput',
  labelCol = { span: 24 },
  disabled
}) => {
  const nameInput = type === 'number' ? 'Number' : 'String';

  useEffect(() => {
    form.setFieldsValue({
      [fieldName + nameInput + name]: value,
    });
  }, []);

  return (
    <FormInput
      label={label}
      name={fieldName + nameInput + name}
      type={type === 'number' ? 'number' : 'text'}
      labelCol={labelCol}
      disabled={disabled}
    />
  );
};
