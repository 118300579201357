import { FC, useEffect, useState } from 'react';
import { IProductPrice } from '../../../../../types/entities/ProductPrice';
import { InputCustom } from '../../../../../components/AntdCustom/InputCustom';
import { useHttp } from '../../../../../hooks/useHttp';
import { ApiUrl } from '../../../../../types/ApiUrl';
import { Button, Form, FormInstance, Input } from 'antd';

import styles from '../../Product.module.scss';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';

type pricesProps = {
  sku: string;
  form: FormInstance<any>;
};

export const Prices: FC<pricesProps> = ({ sku, form }) => {
    const { isAdmin } = useTypedSelector((state) => state.auth);
  const [prices, setPrices] = useState<IProductPrice[]>([]);
  const [commonPrice, setCommonPrice] = useState<string>('');

  const { request } = useHttp();

  const fetchData = async () => {
    try {
      const response = await request({
        url: `${ApiUrl.PRODUCT}/prices?sku=${sku}`,
        method: 'get',
      });
      setPrices(response.data);
    } catch (error) {}
  };

  const setAllPrice = (e: any) => {
    const pricesClone = structuredClone(prices);
    pricesClone.forEach((price) => {
      price.priceRetail = Number(commonPrice);
      form.setFieldValue(`productPrice_Number${price.id}`, commonPrice);
    });
    setPrices(pricesClone);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div>
        <Form.Item label="Стандартная цена">
          <Input
            type="number"
            onInput={(e: any) => {
              setCommonPrice(e.target.value);
            }}
            value={commonPrice.toString()}
            disabled={!isAdmin}
          ></Input>
          <Button style={{ marginTop: '10px' }} onClick={setAllPrice}>
            Установить общую цену
          </Button>
          <hr />
        </Form.Item>
      </div>
      <div className={styles.pricesWrapper}>
        {prices.map((price) => {
          return (
            <div key={price.id}>
              <InputCustom
                label={`${price.deliveryPoint?.title || 'Неизвестно'} (${
                  price.stockCode
                })`}
                value={price.priceRetail.toString()}
                name={price.id}
                form={form}
                fieldName="productPrice_"
                type={'number'}
                labelCol={{ span: 10 }}
                disabled={!isAdmin}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
